<template>
  <div class="bg container">
    <div class="conBox" style="background: rgba(31, 37, 49, 1);">
      <div class="dfc" style="margin-bottom: 20px">
        <el-page-header style="float: left" @back="$router.go(-1)" :content="$route.params.detail.kb_name"> </el-page-header>

        <div class="dfc" style="float: left;margin-left: 73px;margin-top: -6px;">
          <el-button style="width: 80px!important; height: 32px !important; border: none !important;color: white!important;border-radius: 5px!important;background: rgba(67, 207, 124, 0.6)!important;" type="primary"  size="small" plain @click="uploadVisible = true">上传文档</el-button>
          <el-button style="width: 80px!important; height: 32px !important; border: none !important;color: white!important;border-radius: 5px!important;background: rgba(0, 186, 173, 0.6)!important;margin-bottom: 25px;" type="primary"  size="small" plain @click="dialogVisible = true">上传网址</el-button>
        </div>
      </div>

      <div class="mt10">
        <el-table :data="tableData" height="735" style="width: 100%;background: rgba(31, 37, 49, 1);border: none;">
          <el-table-column type="index" label="序号" align="center" width="70"> </el-table-column>
          <el-table-column prop="file_name" label="文档名称"> </el-table-column>
          <el-table-column prop="timestamp" label="创建时间">
            <template slot-scope="scope">
              {{ scope.row.timestamp | formatDate }}
            </template>
          </el-table-column>
          <el-table-column prop="status" label="文档状态" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 'red'" style="color: #f56c6c">入库失败-切分失败</span>
              <span v-if="scope.row.status == 'green'" style="color: #67c23a">成功入库</span>
              <span v-if="scope.row.status == 'yellow'" style="color: #e6a23c">入库失败-milvus失败</span>
              <span v-if="scope.row.status == 'gray'" style="color: #909399">正在入库</span>
            </template>
          </el-table-column>
          <el-table-column style="color: black !important;;" align="center" fixed="right" label="操作" width="180">
            <template slot-scope="scope">
              <span class="cp" style="color: #e13535" @click="handleDel(scope.row.file_id)">删除</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog title="上传文档" :visible.sync="uploadVisible" width="25%" :destroy-on-close="true" append-to-body>
        <div class="formBox">
          <el-upload class="upload-demo" drag name="files" :action="action" :data="uploadData" :on-success="uploadSuccess" :on-error="uploadErr">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </div>
        <!-- <div slot="footer" class="dialog-footer">
        <el-button @click="uploadClose">取 消</el-button>
        <el-button type="primary" @click="addItem">保 存</el-button>
      </div> -->
      </el-dialog>
      <el-dialog title="上传网址" :visible.sync="dialogVisible" width="38%" :destroy-on-close="true" append-to-body>
        <div class="formBox">
          <el-input placeholder="请输入网址" maxlength="250" v-model="content"> </el-input>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button class="cancel_btn" @click="close">取 消</el-button>
          <el-button class="submit_btn" type="primary" @click="editSave">保 存</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { get_list_files, upload_weblinks, del_files } from "@/api/miniature.js";
import UploadFile from "@/components/UploadFile.vue";

export default {
  name: "audioLibrary",
  data() {
    return {
      idx: "",
      detail: {},
      id: "",
      user_id: localStorage.getItem("token"),
      localhosts: this.$onlyId,
      title: "添加音频文件",
      dialogVisible: false,
      uploadVisible: false,
      fileName: "",
      content: "",
      action: process.env.VUE_APP_API_CHAT_URL + "api/local_doc_qa/upload_file",
      form: {
        ipt: "",
      },
      uploadData: {},
      tableData: [],
    };
  },
  filters: {
    formatDate(val) {
      // 提取年、月、日、时、分、秒
      const year = val.substring(0, 4);
      const month = val.substring(4, 6);
      const day = val.substring(6, 8);
      const hour = val.substring(8, 10);
      const minute = val.substring(10, 12);
      const second = "00";

      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    },
  },
  components: {
    UploadFile,
  },
  mounted() {
    this.user_id = localStorage.getItem("token");
    this.id = this.$route.params.detail.kb_id;
    this.uploadData = {
      user_id: this.user_id,
      localhosts: this.localhosts,
      kb_id: this.id,
      mode: "strong",
    };

    this.getData();
  },
  methods: {
    async getData() {
      let { data: res } = await get_list_files({
        kb_id: this.$route.params.detail.kb_id,
        localhosts: this.localhosts,
        user_id: this.user_id,
      });
      console.log(res);
      if (res.code == 200) {
        // this.$message.success(res.msg);
        this.tableData = res.data.details;
      } else {
        this.$message.error(res.msg);
      }
    },
    uploadSuccess(e) {
      if (e.code == 200) {
        this.$message.success(e.msg);
        this.getData();
        this.uploadClose();
      }
    },
    uploadErr(e) {
      this.$message.success(e.msg);
    },
    async addItem() {
      return;
    },

    async editSave() {
      let { data } = await upload_weblinks({
        user_id: this.user_id,
        localhosts: this.localhosts,
        kb_id: this.id,
        url: this.content,
      });
      if (data.code == 200) {
        this.$message.success(data.msg);
        this.getData();
        this.content = "";
        this.dialogVisible = false;
      } else {
        this.$message.error(data.msg);
      }
    },
    async handleDel(idx) {
      const loading = this.$loading({
        lock: true,
        text: "正在删除中，请稍候",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let { data } = await del_files({
        user_id: this.user_id,
        localhosts: this.localhosts,
        kb_id: this.id,
        file_ids: idx,
      });
      loading.close();
      if (data.code == 200) {
        this.$message.success(data.msg);
        this.getData();
      } else {
        this.$message.error(data.msg);
      }
    },
    close() {
      this.dialogVisible = false;
      this.content = "";
    },
    uploadClose() {
      this.uploadVisible = false;
    },
  },
};
</script>
<style lang="less" scoped>

.cancel_btn{
  width: 68px;
  height: 41px;
  opacity: 1;
  border-radius: 10px;
  background: rgba(166, 166, 166, 1);
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 20px 5px 20px;
  color: rgba(56, 56, 56, 1);
  border: none;
}
.submit_btn{
  width: 68px;
  height: 41px;
  opacity: 1;
  border-radius: 10px;
  background: rgba(42, 130, 228, 1);
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 20px 5px 20px;
  border: none;
}
::v-deep{
  .el-message-box__header{
    background: black !important;
  }
}
::v-deep{
  .el-upload-dragger{
    width: 403px;
    height: 178px;
    opacity: 1;
    border-radius: 15.55px;
    background: rgba(28, 28, 36, 1);
    border: none;
    margin: 85px 174px;
  }
  .el-icon-upload:before{
    color: rgba(19, 166, 207, 1);
  }
}
::v-deep{

  .el-input__inner{
    width: 787px;
    height: 219px;
    opacity: 1;
    border-radius: 15.55px;
    background: rgba(28, 28, 36, 1) !important;
    border: none;
    color: white;
    font-size: 20px;
  }
  .el-dialog{
    border-radius: 10px !important;
    width: 872px !important;
    height: 445px;
    opacity: 1;
    background: rgba(31, 37, 49, 1) !important;
  }
  .el-dialog__header{
    text-align: left;
    border-radius: 8px 8px 0px 0px;
    background: rgba(44, 50, 64, 1);
    span{
      color: rgba(11, 224, 178, 1);
    font-size: 18px;
    font-weight: 700;
      text-align: left;
    }
  }
}


::v-deep{
  .el-page-header__content{
    width: auto !important;
  }
  .el-table th.el-table__cell{
    background: rgba(1, 14, 43, 1);
    color: white;
    margin: 10px;
  }
  .el-icon-back{
    display: none;
  }
 .el-page-header__left{
  width: 68px;
  height: 34px;
  opacity: 1;
  border-radius: 30px;
  background: rgba(25, 103, 191, 1);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 6px 12px 7px 19px;
   color: white;
   margin-top: -6px;
}
  .el-page-header__content{
    width: 36px;
    height: 27px;
    opacity: 1;
    /** 文本1 */
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 26.06px;
    color: rgba(11, 224, 178, 1);
    text-align: left;
    vertical-align: top;
  }
  .el-table__header-wrapper .el-table__header thead tr{
    color: black !important;
     background: rgba(1, 14, 43, 1) !important;
  }
  .el-table>thead>tr{
    /*background: rgba(1, 14, 43, 1);*/
  }
  .el-table tr:nth-of-type(odd){
    background: rgba(44, 50, 64, 1);
    border: none;
    color: white;
  }
  .el-table tr:nth-of-type(even){
    background: rgba(10, 4, 31, 1);
    border: none;
    color: white;
  }
  .el-table tr:hover{
    color: black;
  }
  .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
    border-bottom: none;
  }
}
.conBox {
  margin: 20px;
  background: #fff;
  padding: 30px;
  min-height: 87vh;
  border-radius: 8px;

  .cHead {
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
    font-weight: 700;
    font-size: 16px;
    color: #202020;
    line-height: 19px;
    text-align: left;
  }
}
.formBox {
  padding: 0 20px;
}
::v-deep {
  .el-tabs__item.is-active {
    color: #186df5;
  }
  .el-tabs__active-bar {
    background: #186df5;
  }

  .el-menu {
    width: 92%;
  }

  .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: 2px solid #186df5;
  }

  .el-textarea__inner {
    border: 0;
    color: #000000;
    background: #f4f5f7;
    padding: 16px 20px !important;
    font-size: 15px !important;
  }
}
</style>
